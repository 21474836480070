import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class FrecuenciasService {
  listarFrecuencias () {
    return http.get(`${baseUrl}/frecuencias/listar`, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/frecuencias/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getFrec (id) {
    return http.get(`${baseUrl}/frecuencias/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crearFrec (data) {
    return http.post(`${baseUrl}/frecuencias`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarFrec (data) {
    return http.put(`${baseUrl}/frecuencias/${data.Code}`, data, {
      headers: {
        loading: true
      }
    })
  }

  eliminarFrec (id) {
    return http.delete(`${baseUrl}/frecuencias/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
