<template>
  <section>
    <div class="grid grid-cols-2 gap-5 mx-5">
      <div class="flex flex-col col-start-1">
        <span class="font-bold">cod</span>
        <InputText class="rounded w-full border uppercase" :disabled="idFrec" type="text" v-model="model.Code" />
        <MessageError :text="errors.Code"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Descripción </span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.Name" />
        <MessageError :text="errors.Name"/>
      </div>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import FrecuenciaService from '../../../../../services/frecuencias.service'

// instancias
const _FrecuenciaService = ref(new FrecuenciaService())
const emit = defineEmits(['customChange'])
const props = defineProps({
  idFrec: String
})
// referencias

const validationSchema = yup.object({
  Code: yup.string().required('El codigo es requerido').label(''),
  Name: yup.string().required('El nombre es requerido').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

  useField('Code', null, { initialValue: '' })
  useField('Name', null, { initialValue: '' })

const onSubmit = handleSubmit(async (values) => {
    if (!values) throw new Error('Error de validación')
    for (const [index, item] of Object.entries(model)) {
      if (typeof item === 'string') {
        model[index] = item.toUpperCase()
      }
    }
    if (props.idFrec) {
      _FrecuenciaService.value.editarFrec(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Frecuencia Actualizada',
          text: 'La Frecuencia se ha actualizado correctamente'
        }).then(() => {
          emit('crearFrec')
        })
      })
    } else {
      _FrecuenciaService.value.crearFrec(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Frecuencia creada',
          text: 'La Frecuencia se ha creado correctamente'
        }).then(() => {
          emit('crearFrec')
        })
      })
    }
})
const resetFilters = () => {
  model.value = {
    Code: '',
    Name: ''
  }
}

onMounted(() => {
  if (props.idFrec) {
    _FrecuenciaService.value.getFrec(props.idFrec).then(async ({ data }) => {
      for (const [index, item] of Object.entries(data)) {
        model[index] = item
      }
    })
  }
})
</script>
<style>
</style>
